// Lineup.js
import React, { useRef, useEffect, useState } from 'react';
import '../css/lineup.css';
import mandidextrous_img from '../img/artist_img/mandidextrous.png'
import wand_img from '../img/artist_img/wand.png'
import smote_img from '../img/artist_img/smote.png'
// import junior_brother_img from '../img/artist_img/junior brother.png'
import pippa_bundell_img from '../img/artist_img/pippa_bundell.png'
import clt_img from '../img/artist_img/clt_drp.png'
import yard_img from '../img/artist_img/yard.png'
import kute_img from '../img/artist_img/kute.png'
import magoo_img from '../img/artist_img/magoo.png'
import gaia_img from '../img/artist_img/gaia.png'
import morayleisurecentre_img from '../img/artist_img/moray_leisure_centre.png'
import pc_world_img from '../img/artist_img/pc_world.png'
import craig_john_img from '../img/artist_img/craig_john.png'
import going_off_img from '../img/artist_img/going_off.jpg'
import hometown_img from '../img/artist_img/hometown.png'
import coffin_mulch_img from '../img/artist_img/coffin_mulch.png'
import bass_injection_img from '../img/artist_img/bass_injection.png'
import diall_img from '../img/artist_img/diall.png'
import gotne_img from '../img/artist_img/gotne.png'
import bleaks_img from '../img/artist_img/bleaks.png'
import spit_img from '../img/artist_img/spit.png'
import vibrator_img from '../img/artist_img/vibrator.png'
import underthunder_img from '../img/artist_img/underthunder.png'
import pelk_img from '../img/artist_img/pelk.png'
import ego_img from '../img/artist_img/ego.png'
import soapbox_img from '../img/artist_img/soapbox.png'
import tues_img from '../img/artist_img/tues.png'
import acevision_img from '../img/artist_img/acevision.png'
import xiao_img from '../img/artist_img/xiao.jpg'
import ecoli_img from '../img/artist_img/ecoli.png'
import narna_img from '../img/artist_img/narna.png'
import euphonique_img from '../img/artist_img/euphonique.png'
import klumsy_img from '../img/artist_img/klumsy.png'
import moral_bombing_img from '../img/artist_img/moral_bombing.jpg'
import ultras_img from '../img/artist_img/ultras.jpg'


import mandidextrous_img_webp from '../img/artist_img/mandidextrous.png'
import wand_img_webp from '../img/artist_img_webp/wand.webp'
import smote_img_webp from '../img/artist_img_webp/smote.webp'
// import junior_brother_img_webp from '../img/artist_img_webp/junior brother.webp'
import pippa_bundell_img_webp from '../img/artist_img_webp/pippa_bundell.webp'
import clt_img_webp from '../img/artist_img_webp/clt_drp.webp'
import yard_img_webp from '../img/artist_img_webp/yard.webp'
import kute_img_webp from '../img/artist_img_webp/kute.webp'
import magoo_img_webp from '../img/artist_img_webp/magoo.webp'
import gaia_img_webp from '../img/artist_img_webp/gaia.webp'
import morayleisurecentre_img_webp from '../img/artist_img_webp/moray_leisure_centre.webp'
import pc_world_img_webp from '../img/artist_img_webp/pc_world.webp'
import craig_john_img_webp from '../img/artist_img_webp/craig_john.webp'
import going_off_img_webp from '../img/artist_img_webp/going_off.webp'
import hometown_img_webp from '../img/artist_img_webp/hometown.webp'
import coffin_mulch_img_webp from '../img/artist_img_webp/coffin_mulch.webp'
import bass_injection_img_webp from '../img/artist_img_webp/bass_injection.webp'
import diall_img_webp from '../img/artist_img_webp/diall.webp'
import gotne_img_webp from '../img/artist_img_webp/gotne.webp'
import bleaks_img_webp from '../img/artist_img_webp/bleaks.webp'
import spit_img_webp from '../img/artist_img_webp/spit.webp'
import vibrator_img_webp from '../img/artist_img_webp/vibrator.webp'
import underthunder_img_webp from '../img/artist_img_webp/underthunder.webp'
import pelk_img_webp from '../img/artist_img_webp/pelk.webp'
import ego_img_webp from '../img/artist_img_webp/ego.webp'
import soapbox_img_webp from '../img/artist_img_webp/soapbox.webp'
import tues_img_webp from '../img/artist_img_webp/tues.webp'
import acevision_img_webp from '../img/artist_img_webp/acevision.webp'
import xiao_img_webp from '../img/artist_img_webp/xiao.webp'
import ecoli_img_webp from '../img/artist_img_webp/ecoli.webp'
import narna_img_webp from '../img/artist_img_webp/narna.webp'
import euphonique_img_webp from '../img/artist_img_webp/euphonique.webp'
import klumsy_img_webp from '../img/artist_img_webp/klumsy.webp'
import moral_bombing_img_webp from '../img/artist_img_webp/moral_bombing.webp'
import ultras_img_webp from '../img/artist_img_webp/ultras.webp'

import Blobs from '../Components/Blobs';

// Sample Artist Data
const artistData = [
  {
    id: 1,
    name: 'MANDIDEXTROUS',
    image: mandidextrous_img, 
    webpImage: mandidextrous_img_webp,
    description: 'For over 15 years, Mandidextrous has been an instrumental figure in the UK/European underground music industry. Known for their seamless fusion of D&B and Techno, they captivate audiences with high-energy performances and a nostalgic rave sound.',
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/0oVDzp5DK2caqb6FuL2mhp?utm_source=generator', // Spotify URL
  },
  {
    id: 2,
    name: 'WAND',
    image: wand_img, 
    webpImage: wand_img_webp,
    description: 'Wand is an American psychedelic rock band from Los Angeles, blending crunchy guitar tones with floaty vocals. Known for their prolific output and unique sound, they continue to push boundaries in the psychedelic rock scene.',
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/53by9P3tpXOW1diWCI6MNu?utm_source=generator', // Spotify URL
  },
  {
    id: 4,
    name: 'SMOTE',
    image: smote_img, 
    webpImage: smote_img_webp,
    description: 'Smote brings a raw energy to their music with distinctive drumming, guitar feedback, and droning open-tuned guitars. They blend elements of ancient and otherworldly music with modern, meditative, DIY aesthetics, gaining a reputation as a powerful live band.',
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/343ATWFlCVF1Z3PyDwFEXp?utm_source=generator', // Spotify URL
  },
  {
    id: 5,
    name: 'PIPPA BLUNDELL',
    image: pippa_bundell_img, 
    webpImage: pippa_bundell_img_webp,
    description: 'Pippa Blundell is a Glasgow-grown songstress whose raw, heartfelt lyrics and gentle guitar work create an intense, modern folk sound. Influenced by classical vocal roots and urban storytelling, she wraps listeners in a blanket of harmonies.',
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/7Hi97v7aZnyJhysmVwOIWt?utm_source=generator', // Spotify URL
  },
  {
    id: 6,
    name: 'CLT DRP',
    image: clt_img, 
    webpImage: clt_img_webp,
    description: "CLT DRP (pronounced 'CLIT DRIP') has been turning heads with their unique fusion of punk, electronica, and heavy pop. Their live performances are uncompromising and forceful, creating a sound that's uniquely their own.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/0y7VykZdOB8wIsAQfr2S4M?utm_source=generator', // Spotify URL
  },
  {
    id: 7,
    name: 'YARD',
    image: yard_img, 
    webpImage: yard_img_webp,
    description: 'YARD, a gripping electro-noise band from Dublin, delivers an intense sensory experience. Bridging the gap between techno and noise rock, their eerie performances leave audiences sweaty, energized, and strangely unnerved.',
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/36aHdS6WBzKMBA9pt1FYFS?utm_source=generator', // Spotify URL
  },
  {
    id: 8,
    name: 'KUTE',
    image: kute_img, 
    webpImage: kute_img_webp,
    description: 'Kute is a hardcore band from Glasgow, known for dissonant, fast guitars, shouted vocals, and powerful, ferocious live shows. They have been dominating the local hardcore scene and continue to evolve.',
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/4KEAIKwqrsvEwdprMQxb54?utm_source=generator', // Spotify URL
  },
  {
    id: 9,
    name: 'MISS MAGOO',
    image: magoo_img, 
    webpImage: magoo_img_webp,
    description: 'Miss Magoo is an award-winning UK Jungle DJ, known for her savage skills behind the controls. Her collaborations with pioneers like Nicky Blackmarket and Kenny Ken have earned her a prominent spot in the Jungle music scene.',
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/01GlXWZ8D6Ql80ERLMn7V7?utm_source=generator', // Spotify URL
  },
  {
    id: 10,
    name: 'GAïA',
    image: gaia_img, 
    webpImage: gaia_img_webp,
    description: 'Gaïa is a Scottish-based jazz and neo-soul artist from France, blending beautiful melodic vocals with R&B, jazz, and soul influences. Her music has become a vibrant force in the Glasgow jazz scene.',
    embedType: 'spotify',
    embedUrl: '', // No Spotify link available
  },
  {
    id: 11,
    name: 'MORAY LEISURE CENTRE',
    image: morayleisurecentre_img, 
    webpImage: morayleisurecentre_img_webp,
    description: "Clyde Built Radio resident and EHFM host, Moray Leisure Centre is one of Scotland's finest selectors. Specialising in high-tempo techno, electro, and jungle, her sets are a must-experience in Scotland's dance scene.",
    embedType: 'soundcloud',
    embedUrl: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1793868426&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true', // SoundCloud URL
  },
  {
    id: 12,
    name: 'CRAIG JOHN DAVIDSON',
    image: craig_john_img, 
    webpImage: craig_john_img_webp,
    description: "raig John Davidson is a Scottish singer / songwriter / producer signed to Kiss My Kunst Records (Uk) Recording and releasing music since the early 2000's. Craig has gained a loyal fanbase through touring the U.K. Europe as well as the United States. With his remarkable finger style playing ability Craig mixes sounds of indie acoustic with his root inspiration of folk blues grunge and beyond.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/5TbeV4cUmpF1DYuNzCdSJ2?utm_source=generator', // SoundCloud URL
  },
  {
    id: 13,
    name: 'PC WORLD',
    image: pc_world_img,
    webpImage: pc_world_img_webp,
    description: "The South London duo, PC World, blends EBM and synth-punk into a snarky industrial soundscape, perfect for club rebels and mutant punks alike. The group is a sonic surrender to future shock, sparking a primal desire for more.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/2oEpLj5O6CPZeMVe8k2ruN?utm_source=generator',
  },
  {
    id: 14,
    name: 'GOING OFF',
    image: ultras_img,
    webpImage: ultras_img_webp,
    description: "Going Off sets themselves apart from other Mancunian HC bands with their brutal guitar tone and rabid vocal style, blending Crust, D-beat, Beatdown Hardcore, and Metalcore/Crossover. For fans of Discharge and Amebix.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/2xCCFcGLx1oUlS1AMtfBo9?utm_source=generator',
  },
  {
    id: 15,
    name: 'Hometown Soundsystem',
    image: hometown_img,
    webpImage: hometown_img_webp,
    description: "Hometown Promotion Soundsystem is a Glasgow-based collective specializing in reggae, roots, dancehall, ska, and rocksteady. Their mission is to use reggae music to foster community-focused initiatives.",
    embedType: 'soundcloud',
    embedUrl: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1829361459&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
  },
  {
    id: 16,
    name: 'Coffin Mulch',
    image: coffin_mulch_img,
    webpImage: coffin_mulch_img_webp,
    description: "Coffin Mulch, an old-school death metal band from Glasgow, brings some of the heaviest sounds from the Central Belt with their savage, raw energy.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/75wcm11JP5KtuLmJJUFxZl?utm_source=generator',
  },
  {
    id: 17,
    name: 'Bass Injection',
    image: bass_injection_img,
    webpImage: bass_injection_img_webp,
    description: "Operating out of Scotland, Bass Injection specializes in underground bass music, bringing savagely heavy D&B and fast bassline beats to Glasgow's club scene.",
    embedType: 'soundcloud',
    embedUrl: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1525173604&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
  },
  {
    id: 18,
    name: 'Diall',
    image: diall_img,
    webpImage: diall_img_webp,
    description: "Diall delivers bitter, sarcastic, and demented hardcore from Newcastle. Their music is loaded with ominous riffs, bestial growls, and powerful stomps, leaving their mark on the hardcore scene.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/293YTaAByzdUouPo8L34WC?utm_source=generator',
  },
  {
    id: 20,
    name: 'Girls Of The Northern Embassy',
    image: gotne_img,
    webpImage: gotne_img_webp,
    description: "Girls Of The Northern Embassy are bringing fast-paced D&B, Jungle, and Hardstyle mixes to the stage. DJs Super Han, Sad Spit Sally, and Kayteebee are set to light up the event.",
    embedType: 'soundcloud',
    embedUrl: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1442293888&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
  },
  {
    id: 21,
    name: 'Bleaks',
    image: bleaks_img,
    webpImage: bleaks_img_webp,
    description: "Glasgow's Bleaks are fast hardcore icons, blending DIY punk ethics with ferocious energy. They have made a name for themselves in Scotland's hardcore scene.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/4yrgtOL2Apk0ulZKZygDZt?utm_source=generator',
  },
  {
    id: 22,
    name: 'SPIT',
    image: spit_img,
    webpImage: spit_img_webp,
    description: "Newcastle's SPIT delivers fast, vicious hardcore. Their music is angry and relentless, and they are quickly making a name for themselves in the UK hardcore scene.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/2DGdQY5dW6I2kK5IlszMhg?utm_source=generator',
  },
  {
    id: 23,
    name: 'Vibrator',
    image: vibrator_img,
    webpImage: vibrator_img_webp,
    description: "Vibrator from Aberdeen blends surf rock with punk, pulling influences from bands like Dead Kennedys and Black Flag. Expect smooth tones mixed with high-energy, angst-driven performances.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/album/143oezPdj6fcUN51mwDn5p?utm_source=generator',
  },
  {
    id: 24,
    name: 'UNDERTHUNDER',
    image: underthunder_img,
    webpImage: underthunder_img_webp,
    description: "tues. exist somewhere between the city and a grey beach. It's rap (sort of). Following two EP's, tues. recently headlined a sold out SWG3 Poetry Club and gained radio play on Rinse FM. The track 'Skin from ‘2235 pt. one' received excellent reviews on the podcast Everybody Wants to Play the Hits. ‘Hill' from the same EP was described on Foundation FM as “one of the most exciting rap songs [they] have heard in a long, long time.”",
    embedType: 'soundcloud',
    embedUrl: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1795435048&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
  },
  {
    id: 25,
    name: 'PELK',
    image: pelk_img,
    webpImage: pelk_img_webp,
    description: "Pelk, a DJ and producer from the North East, is heavily influenced by deconstructed club music. His sound is a warped and decimated percussive journey honed across UK and European dancefloors.",
    embedType: 'soundcloud',
    embedUrl: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1288472293&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
  },
  {
    id: 26,
    name: 'EGO',
    image: ego_img,
    webpImage: ego_img_webp,
    description: "Ego is a hardcore five-piece from Leeds, bringing heavy grooves and savage vocals to the UK hardcore scene. Their funk-laced riffs make them stand out in the heavy hardcore genre.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/4wMMAQV23dr2z8HveXw4ER?utm_source=generator',
  },
  {
    id: 27,
    name: 'SOAPBOX',
    image: soapbox_img,
    webpImage: soapbox_img_webp,
    description: "Formed in 2022, Soapbox has built a small but loyal following in Glasgow. Their live shows are raucous, wild, and aggressive, making them one of the city's most exciting new acts.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/5Uz6P4BCpwuOjnznmreqFO?utm_source=generator',
  },
  {
    id: 29,
    name: 'TUES.',
    image: tues_img,
    webpImage: tues_img_webp,
    description: "tues. exist somewhere between the city and a grey beach. It’s rap (sort of). Following two EP’s, tues. recently headlined a sold out SWG3 Poetry Club and gained radio play on Rinse FM. The track 'Skin from ‘2235 pt. one’ received excellent reviews on the podcast Everybody Wants to Play the Hits. ‘Hill’ from the same EP was described on Foundation FM as “one of the most exciting rap songs [they] have heard in a long, long time.”",
    embedType: 'none',
    embedUrl: '',
  },
  {
    id: 31,
    name: 'ACEVISION',
    image: acevision_img,
    webpImage: acevision_img_webp,
    description: "A Scottish-Malawian spoken word poet, actor, and active rapper/musician who creates and releases their own music and pieces independently, ACE's thoughtful mastery of words brings a considered voice to the canvas of the group, literally painting pictures with a thousand words, as well as part of several local bands and collectives in and around Glasgow. As a queer identifying POC, they frequently collaborate with groups and organisations involved in social justice and activism, leading and participating in workshops dedicated to opening conversations around consent, mental health and healing through creativity, accountability and community care",
    embedType: 'none',
    embedUrl: '',
  },
  {
    id: 32,
    name: 'XIAO',
    image: going_off_img,
    webpImage: going_off_img_webp,
    description: "Formed in 2019, Xiao is a hardcore band from Sweden that can go from the fast brutal speed of power violence and D-beat one moment, and slower and measured the next. XIAO is a musical onslaught, keeping the audience on a knife edge for the whole set, described as an ‘unrelenting assault on the senses’, this band doesn’t hold back, come and see it for yourself.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/7p7bBRBk6IU0X0lqIGj0fJ?utm_source=generator',
  },
  {
    id: 33,
    name: 'E-COLI',
    image: ecoli_img,
    webpImage: ecoli_img_webp,
    description: "E-coli has refined his sound to be a heavy blend of Frenchcore and Hardtek, with balkan, gypsy and world melodies. It is uplifting and fun music, always with hard kicks and heavy bass. He is a core member of Amen4Tekno, and has also had releases on Peacock Records, Undergroundtekno, Frenchcore S’il Vous Plait, Audiogenic, Jigsore Sound, Irritant and many more. He also has a live project with Ed Cox (Ed Cox & E-Coli) blending their two styles and featuring live accordion and keys, often with many guest musicians to make it a full show.In 2021 he continues to push his Frenchcore and Hard Tek sound to have heavier kicks and more brass and melodies, ready to destroy dancefloors.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/2MPy1k969lurKeHRzB5ljY?utm_source=generator',
  },
  {
    id: 35,
    name: 'NARNA',
    image: narna_img,
    webpImage: narna_img_webp,
    description: "Leeds based mixed genre DJ and Producer, taking elements from Grime, Jungle, Footwork, Dubstep and DnB. He is on a steady trajectory ripping through the UK rave scene and releases on: Psyked, OTC Recordings, Nazakat, No More Parties, Militant Music, Northside, Deep In The Jungle, Distorted Audio, Beatz On Toast, StepTwice and many more.",
    embedType: 'none',
    embedUrl: '',
  },
  {
    id: 37,
    name: 'EUPHONIQUE ',
    image: euphonique_img,
    webpImage: euphonique_img_webp,
    description: "With beats on respected D&B labels such as Hospital, Born On Road, Jungle Cakes, Dread Recordings, Ruffneck Ting and her own imprint Subwoofah Records, Euphonique’s high energy style has been part of the landscape since she emerged in 08 and has led to heavy plays on Radio 1 and 1Xtra; nominations at the We Love Jungle Awards and Drum & Bass Awards; collabs with Bou, Selecta J-Man, Gray, Redders, Aries, DJ Hybrid, Kelvin 373, Madrush MC, Bam Bam, MC Frost and performances at iconic dances. Glastonbury to Boomtown, Warehouse Project to Hospitality, E1 to Fabric, UK to Europe to Asia and beyond; Euphonique has taken her art around the world and across the airwaves. Broadcaster since ‘09, she currently hosts a show with jungle pioneer Dazee on SWU.FM.",
    embedType: 'none',
    embedUrl: '',
  },
  {
    id: 39,
    name: 'KLUMZYKHEMIST',
    image: klumsy_img,
    webpImage: klumsy_img_webp,
    description: "Since headlining her first show in the States and returning to the UK in 2019, KlumzyKhemist has been non-stop on making her sound heard. Her reputation for fast-paced, heavy, and unique sets started to buy her attention from some of the most respected events and venues, KlumzyKhemist remains experimental in her music tastes and true to her colours. Well known for her energetic sets filled with chops and double drops, KlumzyKhemist focuses on surprising the crowd as much as possible. Taking inspiration from all genres of music, her style ranges from mixing Neurofunk Drum and Bass, to Dancefloor, Jungle, Footwork, and everything in between, believing in the importance of never sticking to one sound.",
    embedType: 'none',
    embedUrl: '',
  },
  {
    id: 40,
    name: 'MORAL BOMBING',
    image: xiao_img,
    webpImage: xiao_img_webp,
    description: "Music of Dortmund-based emo/powerviolence/grindcore squad Moral Bombing is raw, energetic and emotionally and politically charged. This band has it in spades, combining the most emotive elements of screamo, hardcore and grind, while still finding a way to retain originality. Expect fast, savage and unrelenting powerful sounds with an emotional tint.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/7sS4kf4YifD7wl6otq0c5A?utm_source=generator',
  },
  {
    id: 41,
    name: 'ULTRAS',
    image: moral_bombing_img,
    webpImage: moral_bombing_img_webp,
    description: "Hailing from Oakland, Ultras are a rip roar of relentless, super fast hardcore punk featuring members of World Peace, Fentanyl and more. Signed to Convulse Records, Ultras are a take-no-shit serious fastcore punch to the head, there's no sugarcoated music here, it's a blisteringly raw barrage of thrash, feedback, and vocal shredding power. Come and see for yourself.",
    embedType: 'spotify',
    embedUrl: 'https://open.spotify.com/embed/artist/2bhHsqSDcaIvnRzsfVKhIK?utm_source=generator',
  }
  // Add more artist objects here
];

const Lineup = () => {
  const [selectedArtist, setSelectedArtist] = useState(null);
  const popupRef = useRef(null);

  // Function to open the popup/modal with artist details
  const handleArtistClick = (artist) => {
    setSelectedArtist(artist);
  };

  // Function to close the popup/modal
  const closePopup = () => {
    setSelectedArtist(null);
  };

  // Close popup when clicking outside of the popup content
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    };

    if (selectedArtist) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [selectedArtist]);

  // Render the correct iframe based on embed type
  const renderEmbed = (artist) => {
    switch (artist.embedType) {
      case 'spotify':
        return (
          <iframe
            src={artist.embedUrl}
            width="100%"
            height="152"
            frameBorder="0"
            allowFullScreen=""
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
            title="Spotify Player"
          ></iframe>
        );
      case 'youtube':
        return (
          <iframe
            width="100%"
            height="315"
            src={artist.embedUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YouTube Player"
          ></iframe>
        );
      case 'vimeo':
        return (
          <iframe
            src={artist.embedUrl}
            width="100%"
            height="315"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Vimeo Player"
          ></iframe>
        );
      case 'soundcloud':
        return (
          <iframe
            width="100%"
            height="166"
            scrolling="no"
            frameBorder="no"
            allow="autoplay"
            src={artist.embedUrl}
            title="SoundCloud Player"
          ></iframe>
        );
      default:
        return null;
    }
  };

  return (
    <section className="lineup-page" id="lineup">
      <Blobs />
      <div className="lineup-container">
        <h1>Lineup</h1>
        <div className="artist-grid">
          {artistData.map((artist) => (
            <div key={artist.id} className="artist-card" onClick={() => handleArtistClick(artist)}>
              <div className="artist-card-container">
                {/* Use <picture> element for WebP and fallback */}
                <picture>
                  {/* WebP image */}
                  <source srcSet={artist.webpImage} type="image/webp" />
                  {/* Fallback to original image (optional) */}
                  <img src={artist.image} alt={artist.name} className="artist-image" />
                </picture>
              </div>
              <p className="artist-name">{artist.name}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Modal/Popup for showing artist details */}
      {selectedArtist && (
        <div className="artist-popup">
          <div className="popup-content" ref={popupRef}>
            <div className="button-container">
              <button className="close-button courier" onClick={closePopup}>
                close &times;
              </button>
            </div>

            {/* Display the WebP image directly without conversion */}
            <picture>
              <source srcSet={selectedArtist.webpImage} type="image/webp" />
              <img src={selectedArtist.image} alt={selectedArtist.name} className="popup-image" />
            </picture>

            <h2>{selectedArtist.name}</h2>
            <p className="courier">{selectedArtist.description}</p>
            <div className="media-embed">
              {renderEmbed(selectedArtist)}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Lineup;